@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

button:focus-visible:not([data-baseweb]) {
  outline: rgb(39, 100, 241) solid 3px;
}

ul,
ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ul {
  list-style-type: disc;
}

ol li {
  list-style-type: decimal;
}

#root {
  height: 100vh;
  width: 100%;
}

.fr-view a {
  color: #0000ee;
  text-decoration: underline;
}
